import React from 'react'
import GridLayoutRenderer from '../components/content/GridLayoutRenderer'
import MainLayout from '../layouts/MainLayout'
import PageContext from '../types/PageContext'

const DefaultPageTemplate: React.FunctionComponent<DefaultPageTemplateProps> = (props: DefaultPageTemplateProps) => {
    const { pageContext } = props
    const banner = pageContext.grids
        ?.filter((g) => g.slot === 'banner')
        .map((grid) => <GridLayoutRenderer key={`grid_${grid.directusId}`} pageContext={pageContext} rootItem={grid} />)
    const gridElements = pageContext.grids
        ?.filter((g) => g.slot !== 'banner')
        ?.map((grid) => <GridLayoutRenderer key={`grid_${grid.directusId}`} pageContext={pageContext} rootItem={grid} />)

    return (
        <MainLayout pageContext={pageContext} banner={banner}>
            {gridElements}
        </MainLayout>
    )
}

export default DefaultPageTemplate

interface DefaultPageTemplateProps {
    pageContext: PageContext<any>
}
